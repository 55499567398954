@tailwind base;
@tailwind components;
@tailwind utilities;
.scroll-smooth{
    scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Inter:wght@200;500&display=swap');
:root {
    --font-mono : 'IBM Plex Mono', monospace;
    --font-inter : 'Inter', sans-serif ;
    --font-monter :'Montserrat', sans-serif;
    

}
.mono {
    font-family: var(--font-mono);
}
.inter {
    font-family: var(--font-inter);
}
.monter{
    font-family: var(--font-monter);
}
/* ::-webkit-scrollbar {
    display: none;
} */

::-webkit-scrollbar {
    width: 0.2vw;
}

::-webkit-scrollbar-track {
    background-color: #0A192F;
}

::-webkit-scrollbar-thumb {
    background-color: #25364e;
    border-radius: 1px;
}