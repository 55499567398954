@tailwind base;
@tailwind components;
@tailwind utilities;

.scroll-smooth{
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 0.2vw;
}

::-webkit-scrollbar-track {
    background-color: #0A192F;
}

::-webkit-scrollbar-thumb {
    background-color: #25364e;
    border-radius: 1px;
}
.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
  }
  .reveal.active{
    transform: translateY(0);
    opacity: 1;
    
  }

  .fadeInUp {
    animation: fadeInUp 2s ;
} 

@keyframes fadeInUp{
    0%{transform:translate(0px, 100px); opacity: 0;}
    100%{transform:translate(0px, 0); opacity: 1;}
    }
